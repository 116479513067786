<template>
  <component
    :is="comingSoon ? 'div' : 'router-link'"
    class="card-container"
    :class="{disabled: comingSoon}"
    :to="comingSoon ? null : { name: (type + 'Page'), params: { slug: _slug }}"
    :style="{
      cursor: comingSoon ? 'auto' : cursor,
      pointerEvents :  comingSoon ? 'none' : 'auto'
    }"
  >
    <div class="card-wrapper">
      <v-img
        :lazy-src="getLazySrc(gallery[0])"
        :src="optimizedImageURL(gallery[0], { width: 600, quality: 95 })"
        class="card-image"
      />
      <div v-if="comingSoon" class="card-coming-soon">
        <span class="h5">Coming Soon</span>
      </div>
    </div>
    <div class="card-content">
      <h4 class="mr-4">{{ title }}</h4>
      <arrow v-if="!comingSoon" class="arrow"/>
    </div>
  </component>
</template>

<script>
import { getLazySrc, optimizedImageURL } from '@/services/OptimizedImage'
import Arrow from '@/assets/img/cta-arrow.svg'

export default {
  name: 'Card',
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      required: true
    },
    _slug: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    gallery: {
      type: Array,
      required: false
    },
    comingSoon: {
      type: Boolean
    },
    cursor: {
      type: String,
      required: false,
      default: 'pointer'
    }
  },
  components: { Arrow },
  methods: {
    getLazySrc,
    optimizedImageURL
  }
}
</script>

<style lang="scss" scoped>
.card-container {
  cursor: inherit;
  display: block;
  margin: rem(6px);
  background-color: #fff;
  border-radius: rem(36px);
  box-shadow: 0 0 rem(10px) $color-detail;
  color: $color-text-dark;
  text-decoration: none;

  @include breakpoint ('sm-and-down') {
    margin: rem(5px);
  }
}

h4 {
  font-size: rem(19px);

  @include breakpoint ('sm-and-down') {
    font-size: rem(16px);
  }
}

.card-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: rem(36px 36px 0 0);
}

.card-coming-soon {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: rem(12px);
}

.card-image {
  width: 100%;
  height: rem(413px);
  background-position: center;
  @include breakpoint ('lg-only') {
    height: rem(310px);
  }

  @include breakpoint ('md-only') {
    height: rem(250px);
  }

  @include breakpoint ('sm-only') {
    height: rem(280px);
  }

  @include breakpoint ('xs-only') {
    height: rem(190px);
  }
}

.card-content {
  position: relative;
  padding-top: rem(15px);
  padding-right: rem(24px);
  padding-bottom: rem(22px);
  padding-left: rem(24px);

  @include breakpoint ('md-and-down') {
    padding: rem(15px) rem(30px);
  }

  @include breakpoint ('sm-and-down') {
    padding: rem(10px) rem(20px);
  }

  h4 {
    height: rem(54px);
    overflow: hidden;

    &::after {
      content: '';
      text-align: left;
      position: absolute;
      bottom: rem(22px);
      right: rem(36px);
      width: rem(70px);
      height: rem(27px);
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);

      @include breakpoint ('md-and-down') {
        bottom: rem(15px);
      }
    }
  }

  svg {
    position: absolute;
    bottom: rem(20px);
    right: rem(20px);
    width: rem(12px);
    height: rem(12px);

    .card-container:hover & {
      animation: arrowHover 0.5s;
    }
  }
}

::v-deep .v-image__image {
  transition: transform 0.4s;

  .card-container:hover & {
    transform: scale(1.1);
  }
}
</style>
